

































































































import {Component} from "vue-property-decorator";
import Vue from "vue";
import VueUploadComponent from "vue-upload-component";

@Component({
  components: {
    VueUploadComponent,
  },
})
export default class MediaUploadPage extends Vue {
  private files = [];
  private name = 'file';

  private get uploadUrl(): string {
    return `http://localhost:1323/tree/${this.$route.params.list}/${this.$route.params.node}/upload`;
  }

  private inputFilter(newFile: File | undefined, oldFile: File | undefined, prevent: () => any) {

    if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
      // Create a blob field
      // 创建 blob 字段
      newFile.blob = ''
      let URL = window.URL || window.webkitURL
      if (URL && URL.createObjectURL) {
        newFile.blob = URL.createObjectURL(newFile.file)
      }
      // Thumbnails
      // 缩略图
      newFile.thumb = ''
      if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
        newFile.thumb = newFile.blob
      }
    }
  }

  private inputFile(newFile: File | undefined, oldFile: File | undefined) {

    // Automatically activate upload
    if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
      if (!this.$refs.upload.active) {
        this.$refs.upload.active = true
      }
    }
  }

}

